<template>
  <div class="card">
    <div class="card-header">
      Magasin choisi : <b>{{ shop.name }}</b>
    </div>
    <div class="card-body">
      <div class="d-flex flex-column">
        <span><b># </b> {{ shop.id }}</span>
        <span><b>Nom : </b>{{ shop.name }}</span>
        <span><b>N° de lot : </b> {{ shop.lot }}</span>
        <span><b>Contact : </b> {{ shop.contactEmail }}</span>
        <span><b>Centre : </b> {{ shop.center.name }}</span>
      </div>

      <div class="d-flex justify-content-end">
        <button class="btn btn-sm btn-danger mr-2" v-on:click="emitRemoveShop" v-if="isList && index >= 1">
          Retirer le magasin ?
        </button>
        <button class="btn btn-sm btn-info" v-on:click="emitChangeShop">Changer de magasin ? </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shop: Object,
    isList: Boolean,
    index: Number
  },
  methods: {
    emitChangeShop() {
      this.$emit('emit-change-shop', this.shop)
    },
    emitRemoveShop() {
      this.$emit('emit-remove-shop', { shop: this.shop, index: this.index })
    }
  },
}
</script>
